import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PlusCircle } from 'lucide-react';

const styles = {
  promoteWrapper: {
    color: '#ffffff',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    minHeight: '94vh',
  },
  linkWrapper: {
    color: '#ffffff',
    textDecoration: 'none',
  },
  promoteTitleContainer: {
    textAlign: 'center',
    padding: '30px 45px 15px 25px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  promoteTitle: {
    margin: 0,
    paddingLeft: '12px',
  },
  createPromoWrapper: {
    background: 'linear-gradient(17deg, rgba(60, 0, 255, 0.85) 0%, rgb(134, 1, 255) 26%, rgb(255, 10, 103) 86%)',
    borderRadius: '5px',
    fontSize: '17px',
  },
  createPromoLink: {
    display: 'flex',
    gap: '8px',
    padding: '10px',
  }
};

const PromoteContainer = () => {
  return (
    <div style={styles.promoteWrapper}>
      <Row nogutters="true">
        <Col sm={12} style={styles.promoteTitleContainer}>
          <h3 style={styles.promoteTitle}>Promo Manager</h3>

          <div style={styles.createPromoWrapper}>
            <Link to="createpromo" style={{ ...styles.linkWrapper, ...styles.createPromoLink }}>
              <PlusCircle size={20} />
              Create Promo
            </Link>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PromoteContainer;
