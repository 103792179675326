import { useEffect, useState } from "react";
import axios from "axios";
import { getApiUrl } from '../config/siteConfig';

const AUTH_URL = `https://www.tiktok.com/v2/auth/authorize?client_key=awzm4rhh5bejrcrz&response_type=code&scope=user.info.basic,user.info.profile,user.info.stats,video.list&redirect_uri=https://knkt.io/connectTiktok&state=8c61riez3u`;

const TikTokButton = (props) => {
    const [accessToken, setAccessToken] = useState(null);
    const { knktId } = props; // Replace with actual user ID
    const { location } = props;
    console.log("location: ", location);

    useEffect(() => {
        const handleMessage = (event) => {
            console.log("refreshing tiktok token", event);
            if (event.data === "tiktok_login_complete") {
                fetchSession(); // refresh session data
            }
        };
        window.addEventListener("message", handleMessage);
        return () => window.removeEventListener("message", handleMessage);
    }, []);

    useEffect(() => {
        if (location.pathname === "/connection") {
            fetchSession();
        }
    }, [location.pathname]);

    const fetchSession = async () => {
        axios.get(getApiUrl() + `/api/v1/tiktok/session?knktId=${knktId}`, { withCredentials: true })
            .then(res => {
                setAccessToken(res.data.access_token);
                localStorage.setItem("tikTokAccessToken", res.data.access_token);
            })
            .catch(() => localStorage.removeItem("tikTokAccessToken"));
    };

    const handleLogin = () => {
        const popup = window.open(
            AUTH_URL,
            "TikTok Login",
            "width=500,height=600"
        );

        const checkPopupClosed = setInterval(() => {
            if (!popup || popup.closed) {
                clearInterval(checkPopupClosed);
                fetchSession(); // Fetch session after login window closes
            }
        }, 1000);
    };

    const handleLogout = () => {
        axios.post(getApiUrl() + `/api/v1/tiktok/logout`, { knktId }, { withCredentials: true })
            .then(() => {
                setAccessToken(null);
                localStorage.removeItem("tikTokAccessToken");
            });
    };
    console.log("tikTokAccessToken: ", localStorage.getItem("tikTokAccessToken"))

    if (knktId == null) {
        return <>No knktId</>
    }

    return (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
            <button
                onClick={handleLogin}
                disabled={!!accessToken}
                style={{
                    padding: "10px 20px",
                    fontSize: "16px",
                    cursor: accessToken ? "not-allowed" : "pointer",
                    backgroundColor: accessToken ? "gray" : "#ff0050",
                    color: "#fff",
                    border: "none",
                    borderRadius: "5px"
                }}
            >
                {accessToken ? "Connected ✅" : "Login with TikTok"}
            </button>
            {accessToken && (
                <div style={{ marginTop: "20px" }}>
                    <button
                        onClick={handleLogout}
                        style={{
                            padding: "10px 20px",
                            fontSize: "16px",
                            backgroundColor: "black",
                            color: "#fff",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer"
                        }}
                    >
                        Logout
                    </button>
                </div>
            )}
        </div>
    );
};

export default TikTokButton;
